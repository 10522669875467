import React from 'react';
import { Row, Col } from 'antd';
import downAppStoreSvg from '../../images/icons/down_app_store.svg';
import downCHPlaySvg from '../../images/icons/down_app_chplay.svg';
import iconFacebook from '../../images/icons/ic_facebook.svg';
import iconInstagram from '../../images/icons/ic_instagram.svg';
import iconTwitter from '../../images/icons/ic_twitter.svg';
import { FooterWrapper } from './styles';
import moment from 'moment';

const FooterContact: FC = () => {
  return (
    <FooterWrapper>
      <Row type="flex" align="middle" className="footer-contact">
        <Col md={24} lg={24} sm={24} xl={12}>
          <div className="left-content">
            <div className="t-bold-40px-50 title-left-content">
              Download the Phone a Vet app now
            </div>
            <div className="img-download-group">
              <div className="img-download-item">
                <a
                  href="https://apps.apple.com/us/app/phone-a-vet/id1484582641?ls=1"
                  target="_blank"
                >
                  <img src={downAppStoreSvg} className="img-download-1" />
                </a>
              </div>
              <div className="img-download-item">
                <a
                  href="https://play.google.com/store/apps/details?id=vet.phoneavet.app&hl=en/"
                  target="_blank"
                >
                  <img src={downCHPlaySvg} className="img-download-2" />
                </a>
              </div>
            </div>
            <div className="ic-social-group">
              <a href="https://www.facebook.com/PhoneAVet" target="_blank">
                <img src={iconFacebook} className="ic-social" />
              </a>
              <a href="https://twitter.com/PhoneAVet" target="_blank">
                <img src={iconTwitter} className="ic-social" />
              </a>
              <a href="https://www.instagram.com/phoneavet/" target="_blank">
                <img src={iconInstagram} className="ic-social" />
              </a>
            </div>
          </div>
        </Col>
        <Col md={24} lg={24} sm={24} xl={12}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12} lg={12} xl={12}>
              <div className="t-bold-18px-30 title-content">
                For Veterinarians
              </div>
              <a href="/registration-information" target="_blank">
                <div className="t-normal-16px-24 pd-top-10 description">
                  Registration Information
                </div>
              </a>
              <a href="/download-pav-for-vets-app" target="_blank">
                <div className="t-normal-16px-24 pd-top-10 description">
                  Download “PAV for Vets” App
                </div>
              </a>
              <a href="/application-form-for-veterinarians" target="_blank">
                <div className="t-normal-16px-24 pd-top-10 description">
                  Application form for Veterinarians
                </div>
              </a>
              <a href="/terms-and-conditions-for-veterinarians" target="_blank">
                <div className="t-normal-16px-24 pd-top-10 description">
                  Terms & Conditions for Veterinarians
                </div>
              </a>
            </Col>
            <Col xs={24} md={12} lg={12} xl={12}>
              <div className="t-bold-18px-30 title-content">
                Legal Information
              </div>
              <a href="/terms-and-conditions#terms-and-conditions" target="_blank">
                <div className="t-normal-16px-24 pd-top-10 description">
                  Terms & Conditions
                </div>
              </a>
              <a href="/privacy-policy" target="_blank">
                <div className="t-normal-16px-24 pd-top-10 description">
                  Privacy Policy
                </div>
              </a>
            </Col>
          </Row>
          <div className="t-normal-14px-30 mg-top-24 txt-copy">
            Copyright © {moment().format('YYYY')} -Phone A Vet Pty Ltd. All rights reserved.
          </div>
        </Col>
      </Row>
    </FooterWrapper>
  );
};

export default FooterContact;
